// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-register-success-js": () => import("./../../../src/pages/register-success.js" /* webpackChunkName: "component---src-pages-register-success-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-templates-categories-category-loadmore-js": () => import("./../../../src/templates/categories/category-loadmore.js" /* webpackChunkName: "component---src-templates-categories-category-loadmore-js" */),
  "component---src-templates-categories-category-news-js": () => import("./../../../src/templates/categories/category-news.js" /* webpackChunkName: "component---src-templates-categories-category-news-js" */),
  "component---src-templates-games-game-js": () => import("./../../../src/templates/games/game.js" /* webpackChunkName: "component---src-templates-games-game-js" */),
  "component---src-templates-games-lottery-js": () => import("./../../../src/templates/games/lottery.js" /* webpackChunkName: "component---src-templates-games-lottery-js" */),
  "component---src-templates-page-activation-user-js": () => import("./../../../src/templates/page/activation-user.js" /* webpackChunkName: "component---src-templates-page-activation-user-js" */),
  "component---src-templates-page-default-js": () => import("./../../../src/templates/page/default.js" /* webpackChunkName: "component---src-templates-page-default-js" */),
  "component---src-templates-page-lotto-provider-js": () => import("./../../../src/templates/page/lotto-provider.js" /* webpackChunkName: "component---src-templates-page-lotto-provider-js" */),
  "component---src-templates-page-post-js": () => import("./../../../src/templates/page/post.js" /* webpackChunkName: "component---src-templates-page-post-js" */),
  "component---src-templates-page-register-js": () => import("./../../../src/templates/page/register.js" /* webpackChunkName: "component---src-templates-page-register-js" */),
  "component---src-templates-page-reset-password-js": () => import("./../../../src/templates/page/reset-password.js" /* webpackChunkName: "component---src-templates-page-reset-password-js" */),
  "component---src-templates-redirect-301-js": () => import("./../../../src/templates/redirect-301.js" /* webpackChunkName: "component---src-templates-redirect-301-js" */),
  "component---src-templates-search-js": () => import("./../../../src/templates/search.js" /* webpackChunkName: "component---src-templates-search-js" */)
}

